@function getBrand() {
    @return $brand;
}

@function getBrandColor($key) {
    @return getValue($brandColor, $key);
}

@function getIconVar($key) {
    @return getValue($iconVars, $key);
}

@function getNeutralColors($key) {
    @return getValue($neutral, $key);
}

@function getUtilityColors($key) {
    @return getValue($utility, $key);
}

@function getTextColors($key) {
    @return getValue($text, $key);
}

@function getThemeColors($key) {
    @return getValue($themeColors, $key);
}

@function getShadows($key) {
    @return getValue($shadows, $key);
}

@function getOverlays($key) {
    @return getValue($overlays, $key);
}

@function getFontSizes($key) {
    @return getValue($fontSizes, $key);
}

@function getFontFamily($key) {
    @return getValue($fontFamilies, $key);
}

@function getLineHeight($key) {
    @return getValue($lineHeight, $key);
}

@function getFontWeights($key) {
    @return getValue($fontWeights, $key);
}

@function getFontSrc($key) {
    @return getValue($fontSrc, $key);
}

@function getBreakpoints($key) {
    @return getValue($breakpoints, $key);
}

@function getSizes($key) {
    @return getValue($sizes, $key);
}

// the unit spacing is 8px
@function getSpacingValues($value) {
    @if isNumber($value) {
        @return $minimunUnit * $value;
    } @else if $value == auto {
        @return $value;
    }

    @error "`#{$value}` in not Number.";
    @return null;
}

// the unit value is 4px
@function getSpacingValue($value) {
    @if isNumber($value) {
        @return $minimunUnitValue * $value;
    } @else if $value == auto {
        @return $value;
    }

    @error "`#{$value}` in not Number.";
    @return null;
}

@function getValue($atom, $key) {
    @if map-has-key($atom, $key) {
        @return map-get($atom, $key);
    }

    @error "Unknown `#{$key}` in `#{$atom}`.";
    @return null;
}

@function isNumber($value) {
    @return type-of($value) == 'number';
}

// on temporary basis added
@function getRGBAColors($key) {
    @return getValue($utility, $key);
}

@function getPrimaryColors($key) {
    @return getValue($primary, $key);
}

@function getBlueColors($key) {
    @return getValue($blue, $key);
}

@function getAccentColors($key) {
    @return getValue($accent, $key);
}

@function getRedColors($key) {
    @return getValue($red, $key);
}

@function getGreenColors($key) {
    @return getValue($green, $key);
}

@function getYellowColors($key) {
    @return getValue($yellow, $key);
}

@function getMonetizationColors($key) {
    @return getValue($monetization, $key);
}

@function getAlertColors($key) {
    @return getValue($alert, $key);
}

$brand: var(--brand);

$primary: (
        primaryBase: var(--primaryBase),
        primaryLight1: var(--primaryLight1),
        primaryLight3: var(--primaryLight3),
        primaryLight2: var(--primaryLight2),
        primaryLight4: var(--primaryLight4),
        primaryLight5: var(--primaryLight5),
        primaryLight6: var(--primaryLight6),
        primaryDark1: var(--primaryDark1),
        primaryDark2: var(--primaryDark2)
);

$blue: (
        blueBase: var(--blueBase),
        blueLight1: var(--blueLight1),
        blueLight2: var(--blueLight2),
        blueLight3: var(--blueLight3),
        blueLight4: var(--blueLight4),
        blueLight5: var(--blueLight5),
        blueLight6: var(--blueLight6),
        blueLight7: var(--blueLight7),
        blueLight8: var(--blueLight8),
        blueDark1: var(--blueDark1),
        blueDark2: var(--blueDark2),
        blueDark3: var(--blueDark3)
);

$black: (
        blackBase: var(--blackBase),
        blackLight1: var(--blackLight1),
        blackLight2: var(--blackLight2),
        blackLight3: var(--blackLight3),
);

$accent: (
        accentBase: var(--accentBase),
        accentLight1: var(--accentLight1),
        accentLight2: var(--accentLight2),
        accentLight3: var(--accentLight3),
        accentLight4: var(--accentLight4),
        accentLight5: var(--accentLight5),
        accentLight6: var(--accentLight6),
        accentDark1: var(--accentDark1),
        accentDark2: var(--accentDark2)
);

$yellow: (
        yellowBase: var(--yellowBase),
        yellowLight1: var(--yellowLight1),
        yellowLight2: var(--yellowLight2),
        yellowLight3: var(--yellowLight3),
        yellowLight4: var(--yellowLight4),
        yellowDark1: var(--yellowDark1),
        yellowDark2: var(--yellowDark2),
);

$red: (
        redBase: var(--redBase),
        redLight1: var(--redLight1),
        redLight2: var(--redLight2),
        redLight3: var(--redLight3),
        redLight4: var(--redLight4),
        redLight5: var(--redLight5),
        redDark1: var(--redDark1),
        redDark2: var(--redDark2),
        redDark3: var(--redDark3)
);

$green: (
        greenBase: var(--greenBase)
);

$monetization: (
        monetizationBase: var(--monetizationBase),
        monetizationLight1: var(--monetizationLight1),
        monetizationLight2: var(--monetizationLight2),
        monetizationLight3: var(--monetizationLight3),
        monetizationLight4: var(--monetizationLight5),
        monetizationLight5: var(--monetizationLight4),
        monetizationDark1: var(--monetizationDark1),
        monetizationDark2: var(--monetizationDark2),
        monetizationRedesignLight1: var(--monetizationRedesignLight1),
        monetizationRedesignLight2: var(--monetizationRedesignLight2),
        monetizationRedesignLight3: var(--monetizationRedesignLight3),
        monetizationRedesignLight4: var(--monetizationRedesignLight4),
        monetizationRedesignLight5: var(--monetizationRedesignLight5),
        monetizationRedesignDark1: var(--monetizationRedesignDark1)
);

$brandColor: (
        primary: var(--brandPrimary),
        primaryDark: var(--brandPrimaryDark),
        primaryLight: var(--brandPrimaryLight),
        accent: var(--brandAccent),
        accentDark: var(--brandAccentDark),
        accentLight: var(--brandAccentLight),
        primaryGrey: var(--brandPrimaryGrey),
);

$neutral: (
        black: var(--black),
        blackGradients: var(--blackGradients),
        blackBackground: var(--blackBackground),
        blackDividers: var(--blackDividers),
        blackDisabled: var(--blackDisabled),
        blackHintText: var(--blackHintText),
        blackSecondaryText: var(--blackSecondaryText),
        blackIcons: var(--blackIcons),
        blackText: var(--blackText),
        blackLight2:var(--neublackLight2),
        white: var(--white),
        whiteGradients: var(--whiteGradients),
        whiteDividers: var(--whiteDividers),
        whiteDisabled: var(--whiteDisabled),
        whiteHintText: var(--whiteHintText),
        whiteHiglightBackground: var(--whiteHiglightBackground),
        whiteSecondaryText: var(--whiteSecondaryText),
        whiteIcons: var(--whiteIcons),
        whiteText: var(--whiteText),
        background: var(--background),
        greyBorder: var(--greyBorder),
        lightGrey: var(--lightGrey),
        blackDividers2: var(--blackDividers2),
        background2: var(--background2),
        blackBackground2: var(--blackBackground2),
        blackBackground3: var(--blackBackground3),
        blackBackground4: var(--blackBackground4),
        redDisbabled: var(--redDisbabled),
        blackOpaque: var(--blackOpaque),
        disabled: var(--disabled),
  // new ashish
        backgroundDark: var(--backgroundDark),
        gray: var(--gray)
);

$utility: (
        error: var(--utilError),
        errorDark: var(--utilErrorDark),
        errorLight: var(--utilErrorLight),
        facebook: var(--facebook),
        messenger: var(--messenger),
        whatsapp: var(--whatsapp),
        google: var(--google),
        monetization: var(--utilMonetization),
        monetizationDark: var(--utilMonetizationDark),
        monetizationLight: var(--utilMonetizationLight),
        infoBadge: var(--infoBadge),
        promotedBadge: var(--promotedBadge),
        chatPrimary: var(--chatPrimary),
        chatPrimary1: var(--chatPrimary1),
        chatSecondary: var(--chatSecondary),
        disabled: var(--disabledUtility),
        disabledText: var(--disabledText),
        label: var(--utilLabel),
        labelGhost: var(--labelGhost),
        goodAlert: var(--goodAlert),
        veryGoodAlert: var(--veryGoodAlert),
        worstAlert: var(--worstAlert),
        chatSecondary1: var(--chatSecondary1),
        chatLabelBackground: var(--chatLabelBackground),
        offerHighlight: var(--offerHighlight),
        pinToTopBadge: var(--pinToTopBadge),

        // rgba color
        primarybase15: var(--primarybase15),
        primarybase04: var(--primarybase04),
        primarybase19: var(--primarybase19),
        primarybase80: var(--primarybase80),
        blueBase29: var(--blueBase29),
        blueBase25: var(--blueBase25),
        blueBase50: var(--blueBase50),
        blueLight447: var(--blueLight447),
        disabled40: var(--disabled40),
        error20: var(--error20),
        infoBadge50: var(--infoBadge50),
        primaryBaseText15: var(--primaryBaseText15),
        primaryLight1Text80: var(--primaryLight1Text80),
        blackText70: var(--blackText70),
        accent50: var(--accent50),
        blackText50: var(--blackText50),
        blackText20: var(--blackText20),
        black20: var(--black20),
        lightGrey60: var(--lightGrey60),
        accentLight50: var(--accentLight50),
        primaryGrey70: var(--primaryGrey70),
        brandPrimary36: var(--brandPrimary36),
        black25: var(--black25),
        black24: var(--black24),
        black50: var(--black50),
        white87: var(--white87),
        black30: var(--black30)

);

$alert: (
        alertSuccess:var(--alertSuccess),
        alertError:var(--alertError)
);

$text: (
        primaryBaseText: var(--primaryBaseText),
        primaryLight1Text: var(--primaryLight1Text),
        primaryLight2Text: var(--primaryLight2Text),
        primaryLight3text: var(--primaryLight3text),
        primary: var(--textPrimary)
);

$themeColors: (
        valuationCarousalDisabledBg: var(--valuationCarousalDisabledBg),
        valuationCarousalSelectedBg: var(--valuationCarousalSelectedBg),
        valuationProgressColor: var(--valuationProgressColor),
        valuationPriceBg: var(--valuationPriceBg),
        valuationPriceBorder: var(--valuationPriceBorder),
        bookingMainHeading: var(--bookingMainHeading),
        bookingSelectedLocation: var(--bookingSelectedLocation),
        bookingTerms: var(--bookingTerms),
        bookingSelectedDateBorder: var(--bookingSelectedDateBorder),
        bookingConfirmSubtitle: var(--bookingConfirmSubtitle),
        moneBusinessPackageBanner: var(--moneBusinessPackageBanner),
        monePackagePropositionBanner: var(--monePackagePropositionBanner),
        bottomFooterBg: var(--bottomFooterBg),
        topFooterBg: var(--topFooterBg),
        inputBorder: var(--inputBorder),
        headerBg: var(--headerBg),
        notificationBg: var(--notificationBg),
        notificationText: var(--notificationText),
        bulletDisabledBg: var(--bulletDisabledBg),
        bulletBg: var(--bulletBg),
        pageHeadlineBackgroundColor: var(--pageHeadlineBackgroundColor),
        howToSellStepsColor: var(--howToSellStepsColor),
        cityOfTheCityListInLocations: var(--cityOfTheCityListInLocations),
        buttonInLocationsMobileView: var(--buttonInLocationsMobileView),
        locationInfo: var(--locationInfo),
        progressBulletFilled: var(--progressBulletFilled),
        progressBulletBlinker: var(--progressBulletBlinker),
        progressBulletDisabled: var(--progressBulletDisabled),
        mapPinInfoBg: var(--mapPinInfoBg),
        mapPinInfoText: var(--mapPinInfoText),
        grayYellowMap: var(--grayYellowMap),
        grayYellowMapDark: var(--grayYellowMapDark),
        blueLight3primaryLight3: var(--blueLight3primaryLight3),
        blueLight4primaryLight4: var(--blueLight4primaryLight4),
        blueDark1blackBase: var(--blueDark1blackBase)
);

$breakpoints: (
    mobile: 320px,
    phablet: 540px,
    tablet: 960px,
    desktop: 1024px,
    desktopLarge: 1280px,
    desktopXL: 1620px
);

$sizes: (
    mobileWidth: 100%,
    phabletWidth: 100%,
    tabletWidth: 100%,
    desktopWidth: 100%,
    desktopMinWidth: 1024px,
    desktopLargeWidth: 1280px,
    desktopXLWidth: 1620px
);

$minimunUnit: 8px;
$minimunUnitValue: 4px;

$fontFamilies: (
    face: Arial,
    body: (Arial, Helvetica, sans-serif),
    customLTR: var(--customLTRFont),
    customRTL: var(--customRTLFont)
);

$fontSizes: (
    display: 45px,
    headline: 34px,
    title: 24px,
    subtitle: 20px, //subheader
    body2: 16px,
    heading: 18px,
    body1: 14px,
    footnote: 12px, //caption
    caption: 10px, //small
    badge: 8px
);

$lineHeight: (
    display: 48px,
    headline: 40px,
    title: 32px,
    heading: 24px,
    subtitle: 24px,
    body2: 24px,
    body1: 20px,
    footnote: 18px,
    caption: 16px,
    caption2: 12px,
    badge: 10px
);

$fontWeights: (
    bold: 700,
    medium: 500,
    bolder: 600,
    regular: 400
);

$newShadows: (
    small: 0 1px 4px 0 rgba(55, 70, 95, 0.12),
    medium: 0 0 8px 0 rgba(55, 70, 95, 0.12),
    large: 0 10px 20px 0 rgba(55, 70, 95, 0.12),
    xLarge: 0 20px 40px 0 rgba(0, 0, 0, 0.12),
    fab: (0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14)),
    newWidget:  0 4px 7px 3px rgba(0, 0, 0, 0.2),
    newWidgetFooter: 0 -3px 4px 0 rgba(0,0,0,.04),
    insetShadow: inset 0px 3px 6px rgba(55, 70, 95, 0.12),
    valuationFlowCTA: 3px 0px 6px rgba(55, 70, 95, 0.12)
);
$oldShadows: (
    level1: (0 1px 4px 0 rgba(0, 0, 0, 0.1)),
    level2: (0 2px 8px 0 rgba(0, 0, 0, 0.15)),
    level3: (0 0 6px 0 rgba(0, 0, 0, 0.12), 0 6px 6px 0 rgba(0, 0, 0, 0.24)),
    level4: (0 0 24px 0 rgba(0, 0, 0, 0.22), 0 24px 24px 0 rgba(0, 0, 0, 0.3)),
    level5: (0 1px 0 0 rgba(0, 47, 52, .2)),
    level6: (0 1px 3px 0 rgba(0, 47, 52, 0.2), 0 1px 3px 0 rgba(0, 47, 52, 0.2))
);

$shadows: map-merge($oldShadows, $newShadows);

$overlays: (
    normal: rgba(0, 0, 0, 0.8),
    disabled: rgba(255, 255, 255, 0.5)
);

$iconVars: (
	icomoon-font-family: "olx-icons",
	icomoon-font-src: (
		url('../public/fonts/olx-icons.woff2') format('woff2'),
		url('../public/fonts/olx-icons.woff') format('woff'),
		url('../public/fonts/olx-icons.eot') format('embedded-opentype'),
		url('../public/fonts/olx-icons.ttf') format('truetype'),
		url('../public/fonts/olx-icons.svg') format('svg')
	),

    icon-download: "\e900",
    icon-arrow-top-left: "\e902",
    icon-SMS: "\e904",
    icon-more: "\e905",
    icon-circle_stroke: "\e906",
    icon-Approved: "\e908",
    icon-CreditCard: "\e909",
    icon-Cash: "\e90a",
    icon-OK: "\e90b",
    icon-Tips: "\e90c",
    icon-ArrowRight: "\e910",
    icon-ArrowLeft: "\e911",
    icon-ArrowDown: "\e912",
    icon-ArrowUp: "\e913",
    icon-Cross: "\e917",
    icon-Menu: "\e91c",
    icon-Location: "\e91e",
    icon-Crosshairs: "\e91f",
    icon-Classes: "\e920",
    icon-Electronics: "\e922",
    icon-FavoriteOff: "\e923",
    icon-FavoriteOn: "\e924",
    icon-Facebook: "\e925",
    icon-FacebookProfile: "\e927",
    icon-FacebookProfileValidated: "\e928",
    icon-SendMessage: "\e92a",
    icon-GoogleProfile: "\e92b",
    icon-Zoom: "\e92c",
    icon-GoogleProfileValidated: "\e92d",
    icon-MakeAnOffer: "\e92e",
    icon-Flag: "\e92f",
    icon-ShareIt: "\e930",
    icon-PhoneProfile: "\e931",
    icon-Back: "\e932",
    icon-PhoneProfileValidated: "\e933",
    icon-Whatsapp: "\e935",
    icon-Messenger: "\e936",
    icon-Home: "\e938",
    icon-Industries: "\e939",
    icon-Instruments: "\e93a",
    icon-Jobs: "\e93b",
    icon-Games: "\e93d",
    icon-Animals: "\e93e",
    icon-Farming: "\e941",
    icon-Fashion: "\e942",
    icon-RealEstate: "\e943",
    icon-Services: "\e944",
    icon-ForSale: "\e945",
    icon-Smartphones: "\e947",
    icon-Sports: "\e948",
    icon-Furnitures: "\e949",
    icon-Vehicles: "\e94c",
    icon-Babies: "\e94d",
    icon-Exit: "\e94e",
    icon-Edit: "\e94f",
    icon-Trash: "\e951",
    icon-Settings: "\e952",
    icon-Search: "\e953",
    icon-Notifications: "\e954",
    icon-Photo: "\e956",
    icon-Hot: "\e957",
    icon-View: "\e958",
    icon-Chat: "\e959",
    icon-User: "\e95a",
    icon-UserCheck: "\e95b",
    icon-Friends: "\e95d",
    icon-Refresh: "\e961",
    icon-ErrorHaired: "\e962",
    icon-OKHaired: "\e963",
    icon-AddPhotos: "\e964",
    icon-CrossRounded: "\e965",
    icon-OLX: "\e968",
    icon-EyeOff: "\e969",
    icon-DoubleCheckRead: "\e96d",
    icon-attach: "\e96f",
    icon-offer: "\e971",
    icon-meeting: "\e972",
    icon-iconcategories: "\e973",
    icon-sorting: "\e975",
    icon-mobileFilters: "\e976",
    icon-bigView: "\e977",
    icon-gridView: "\e978",
    icon-listView: "\e979",
    icon-mic: "\e97a",
    icon-play: "\e97b",
    icon-pause: "\e97c",
    icon-myads: "\e97d",
    icon-unfollow-friend: "\e97e",
    icon-call: "\e981",
    icon-twotone-more1: "\e9da",
    icon-twotone-nearby: "\e9dc",
    icon-twotone-sale: "\e9dd",
    icon-twotone-rent: "\e9de",
    icon-YoutubeProfile: "\e9df",
    icon-TwitterProfile: "\e9e0",
    icon-InstagramProfile: "\e9e1",
    icon-Key: "\e982",
    icon-Inventory: "\e983",
    icon-Draft: "\e984",
    icon-Addcar: "\e985",
    icon-Lift: "\e93f",
    icon-CarParking: "\e986",
    icon-CCTV: "\e987",
    icon-ClubHouse: "\e988",
    icon-FireAlarm: "\e989",
    icon-Fitness: "\e98a",
    icon-Intercom: "\e98b",
    icon-PlayArea: "\e98c",
    icon-PowerBackup: "\e98d",
    icon-RainWater: "\e98e",
    icon-Security: "\e98f",
    icon-SwimmingPool: "\e990",
    icon-WaterSupply: "\e991",
    icon-twotone-motorbike: "\e9db",
    icon-AttentionHaired: "\e960",
    icon-AddFriend: "\e95c",
    icon-Filters: "\e91d",
    icon-help-outline: "\e901",
    icon-PremiumFeaturedAd: "\e90e"
);

@mixin fontSize($fontSize) {
    font-size: getFontSizes($fontSize);
    line-height: getLineHeight($fontSize);
}

@mixin resetUl {
    margin:0;
    padding:0;
    list-style: none;
}

@mixin ellipsis ($max-width){
    display: inline-block;
    max-width: $max-width;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin padding($value1: null, $value2: null, $value3: null, $value4: null) {
    @if $value1 and $value2 and $value3 and $value4 {
        padding: getSpacingValues($value1) getSpacingValues($value2) getSpacingValues($value3) getSpacingValues($value4);
    } @else if $value1 and $value2 and $value3 {
        padding: getSpacingValues($value1) getSpacingValues($value2) getSpacingValues($value3);
    } @else if $value1 and $value2 {
        padding: getSpacingValues($value1) getSpacingValues($value2);
    } @else if $value1 {
        padding: getSpacingValues($value1);
    }
}

@mixin margin($value1: null, $value2: null, $value3: null, $value4: null) {
    @if $value1 and $value2 and $value3 and $value4 {
        margin: getSpacingValues($value1) getSpacingValues($value2) getSpacingValues($value3) getSpacingValues($value4);
    } @else if $value1 and $value2 and $value3 {
        margin: getSpacingValues($value1) getSpacingValues($value2) getSpacingValues($value3);
    } @else if $value1 and $value2 {
        margin: getSpacingValues($value1) getSpacingValues($value2);
    } @else if $value1 {
        margin: getSpacingValues($value1);
    }
}

@mixin padding4($value1: null, $value2: null, $value3: null, $value4: null) {
    @if $value1 and $value2 and $value3 and $value4 {
        padding: getSpacingValue($value1) getSpacingValue($value2) getSpacingValue($value3) getSpacingValue($value4);
    } @else if $value1 and $value2 and $value3 {
        padding: getSpacingValue($value1) getSpacingValue($value2) getSpacingValue($value3);
    } @else if $value1 and $value2 {
        padding: getSpacingValue($value1) getSpacingValue($value2);
    } @else if $value1 {
        padding: getSpacingValue($value1);
    }
}

@mixin margin4($value1: null, $value2: null, $value3: null, $value4: null) {
    @if $value1 and $value2 and $value3 and $value4 {
        margin: getSpacingValue($value1) getSpacingValue($value2) getSpacingValue($value3) getSpacingValue($value4);
    } @else if $value1 and $value2 and $value3 {
        margin: getSpacingValue($value1) getSpacingValue($value2) getSpacingValue($value3);
    } @else if $value1 and $value2 {
        margin: getSpacingValue($value1) getSpacingValue($value2);
    } @else if $value1 {
        margin: getSpacingValue($value1);
    }
}

@mixin card() {
    width: 100%;
    overflow : hidden;
    box-sizing: border-box;
    background: getNeutralColors(white);
    border: 1px solid getNeutralColors(blackDividers);
    border-radius: getSpacingValue(1);
}

@mixin cardContent() {
    @include padding(2, 3);
}

@mixin cardTitle() {
    @include fontSize(subtitle);
    font-weight: getFontWeights(bold);
    color: getNeutralColors(blackText);
    @include padding(1, 0);
}

@mixin cardSubTitle() {
    @include fontSize(body2);
    font-weight: getFontWeights(bold);
    color: getNeutralColors(blackText);
    @include padding(1, 0);
}

@mixin cardText() {
    @include fontSize(body1);
    margin: 0;
    @include padding(1, 0);
    font-weight: getFontWeights(regular);
    color: getNeutralColors(blackSecondaryText);
}

@mixin modal($width: 108, $height: null) {
    @include margin(0, 2);
    @include padding(2);
    width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    background-color: getNeutralColors(white);
    width: getSpacingValue($width);
    overflow: hidden;
    border-radius: 3px;
    position: relative;
    @if $height {
        height: getSpacingValue($height);
    }
}

@mixin modalNew($width, $height) {
    @include modal($width, $height);
    @include padding4(4,5);
}

@mixin modalWide($width, $height) {
    @include modal($width, $height);
    @include padding(0);
}

@mixin fullPageModal() {
    width: 100%;
    padding: getSpacingValues(3);
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    background-color: getNeutralColors(white);
    height: 100%;
    overflow: auto;
}

@mixin fullPageModalMxCl() {
    background-color: getNeutralColors(white);
    width: 100%;
    height: 100%;
    overflow: auto;
    @include padding4(0);
}

@mixin placeholder($duration: 1s, $fillMode: forwards, $iterationCount: infinite, $timingFunction: linear, $backgroundSize: 900px 104px, $position: relative ) {
    @keyframes placeHolderShimmer{
        0%{
            opacity: 0.3;
        }
        50%{
            opacity: 0.7;
        }
        100%{
            opacity: 0.3;
        }
    }

    animation-duration: $duration;
    animation-fill-mode: $fillMode;
    animation-iteration-count: $iterationCount;
    animation-name: placeHolderShimmer;
    animation-timing-function: $timingFunction;
    background: getNeutralColors(blackDividers);
    background-size: $backgroundSize;
    position: $position;
}

@mixin invertColorBlackWhite() {
    color: getNeutralColors(blackText);
}

@mixin invertColorPrimaryWhite() {
    color: getBrandColor(primary);
}

@mixin invertBackColorPrimaryWhite() {
    background-color: getThemeColors(headerBg);
}

@mixin link($fontSize: body1) {
    text-decoration: none;
    cursor: pointer;
    font-size: getFontSizes($fontSize);
    color: getNeutralColors(blackText);
}

@mixin flatLink($fontSize: 'body1') {
    @include link($fontSize);
    color: getBrandColor(primary);
    font-weight: getFontWeights(bold);
    text-transform: uppercase;
}

@mixin textLink($fontSize: 'body1') {
    @include link($fontSize);
    color: getBrandColor(primary);
    text-decoration: underline;
}

@mixin listLink($fontSize: 'body1') {
    @include link($fontSize);
    color: getNeutralColors(blackSecondaryText);
    &:hover {
        color: getNeutralColors(blackText);
    }
}

@mixin placeholder-chat-mixin($width: null, $height: null, $borderRadius: null, $position: relative) {
    width: $width;
    height: $height;
    display: inline-block;
    border-radius: $borderRadius;
    @include placeholder($position: $position);
}

@mixin notificationDot ($position: absolute, $size: 2) {
    position: $position;
    height: getSpacingValue($size);
    width: getSpacingValue($size);
    border-radius: 50%;
    background-color: getBrandColor(accent);
}

@mixin disableSelect () {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}

@mixin flex ($flex-direction: row, $flex-wrap: nowrap, $align-item: center, $justify-content: center) {
    display: flex;
    flex-direction:$flex-direction;
    flex-wrap: $flex-wrap;
    align-items: $align-item;
    justify-content: $justify-content;
}

.avatarContainer {
    width: getSpacingValue(20);
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;


    .avatarInitials {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 3px solid #9BBBFF;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: getBlueColors(blueLight4);
        color: #3a77ff;
        font-weight: bold;
        font-size: 16px;
    }

    .avatarInitialsOtoplus {
        @extend .avatarInitials;

        color: white;
        background-color: getPrimaryColors(primaryBase);
        border-color: getPrimaryColors(primaryBase);
    }
}

.avatar {
    cursor: pointer;
    @include margin4(0, 2, 0, 0);
}

.dropDownList {
    z-index: 10;
    right: getSpacingValue(8);
    top: getSpacingValue(16);
    width: getSpacingValue(72);
    position: fixed;
    background: getNeutralColors(white);
    box-shadow: getShadows(level3);
    border-radius: getSpacingValue(1);
}

.dropDownList::after {
    bottom: 100%;
    right: getSpacingValue(4);
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: getNeutralColors(white);
    border-width: getSpacingValue(3);
}


.redDotContainer{
    position: absolute;
    top: getSpacingValue(0.5);
    left: getSpacingValue(9);

    // Styles for white ring around the red dot
    padding: getSpacingValue(0.25);
}
